import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import Maintenance from "./views/Maintenance";

class App extends React.Component {
    componentDidMount() {
        // prevent right click save
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });

        window.process = {
            ...window.process,
        };
    };

    render() {
        return (
            <div>
                <Router basename={process.env.PUBLIC_URL}>
                    <div className="container body-content">
                        <Routes>
                            <Route exact path="/" element={<Maintenance />}></Route>
                            <Route path='*' element={<Navigate to='/' />} />
                        </Routes>
                    </div>
                </Router >
            </div >
        );
    }
}

export default App;
