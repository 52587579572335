import React from "react";

class Maintenance extends React.Component {
    render() {
        return (
            <div className="panel">
                <img
                    src="/images/rpa.png"
                    alt="Maintenance icon"
                />
                <h1>Website currently under maintenance.</h1>
                <h2>Please check back soon.</h2>
                <div>
                    <em>
                        Icon made by{" "}
                        <a href="https://www.flaticon.com/authors/parzival-1997" title="Parzival’ 1997">
                            {" "}Parzival’ 1997
                        </a>{" "}from{" "}
                        <a href="https://www.flaticon.com/" title="Flaticon">
                            www.flaticon.com
                        </a>
                    </em>
                </div>
            </div>
        );
    }
}

export default Maintenance;
